import {
    ConnectNamespaceMap,
    creatOKXWalletInfo,
    getTelegramWalletTWAUrl,
    logDebug, NameSpaceKeyEip155,
    NameSpaceKeySOL,
    NameSpaceKeySui, NameSpaceKeyTON,
    openOKXDeeplinkWithFallback,
    openOKXTMAWalletlinkWithFallback,
    WalletInfo
} from "@okxconnect/core";
import {UIWallet, WalletsListConfiguration} from '../../models';
import {mergeConcat} from './array';
import {isInTelegramBrowser, isInTMA} from "./tma-api";
import {isMobile} from "../hooks/isMobile";

export function uiWalletToWalletInfo(uiWallet: UIWallet): WalletInfo {
    // if ('jsBridgeKey' in uiWallet) {
    //     return {
    //         ...uiWallet
    //     };
    // }

    return uiWallet;
}

export function applyWalletsListConfiguration(
    walletsList: WalletInfo[],
    configuration?: WalletsListConfiguration
): WalletInfo[] {
    if (!configuration) {
        return walletsList;
    }

    if (configuration.includeWallets?.length) {
        walletsList = mergeConcat(
            'name',
            walletsList,
            configuration.includeWallets.map(uiWalletToWalletInfo)
        );
    }

    return walletsList;
}

export function supportsDesktop(walletInfo: WalletInfo): boolean {
    return walletInfo.platforms.some(w => ['macos', 'linux', 'windows'].includes(w));
}

export function supportsMobile(walletInfo: WalletInfo): boolean {
    return walletInfo.platforms.some(w => ['ios', 'android'].includes(w));
}

export function supportsExtension(walletInfo: WalletInfo): boolean {
    return walletInfo.platforms.some(w => ['chrome', 'firefox', 'safari'].includes(w));
}

export function eqWalletName(wallet1: { name: string; appName: string }, name?: string): boolean {
    if (!name) {
        return false;
    }

    return (
        wallet1.name.toLowerCase() === name.toLowerCase() ||
        wallet1.appName.toLowerCase() === name.toLowerCase()
    );
}


export function inTg(): boolean {
    // return true
    return isInTelegramBrowser() || isInTMA()
}

export function showTonTgWallet(){
    // return false
    if (!inTg()){
        return false
    }
    return true
}

const showTgNameSpace = [NameSpaceKeySui,NameSpaceKeySOL,NameSpaceKeyEip155,NameSpaceKeyTON]
// const showTgNameSpace = [NameSpaceKeySui,NameSpaceKeySOL,NameSpaceKeyEip155]

export function showUniversalTgWalletByRequestNameSpaces(nameSpaces?:ConnectNamespaceMap,optionalNameSpace?:ConnectNamespaceMap){
    if (!inTg()){
        return false
    }
    return EqnameSpace(nameSpaces) && EqnameSpace(optionalNameSpace)
}

export function showUniversalTgWalletBySessionNameSpaces(sessionNameSpace?: ConnectNamespaceMap) {
    if (!inTg()) {
        return false
    }
    return EqnameSpace(sessionNameSpace)
}

function EqnameSpace(_?:ConnectNamespaceMap):boolean{
    return true
    // try {
    //     if (!nameSpaceMap) return true
    //     const keys = Object.keys(nameSpaceMap);
    //     return  keys.every(element => showTgNameSpace.includes(element));
    // }catch (e){
    //     return false
    // }
}

export function defaultOpenUniversalLink(nameSpaces?:ConnectNamespaceMap,optionalNameSpace?:ConnectNamespaceMap):boolean{
    if (!showUniversalTgWalletByRequestNameSpaces(nameSpaces,optionalNameSpace)){
        return isMobile()
    }
    return false
}

export function defaultOpenTonUniversalLink():boolean{
    if (!showTonTgWallet()){
        return isMobile()
    }
    return false
}


export function isAppWallet(walletInfo?:{name:string} | undefined | null):boolean{
    if (!walletInfo) return false
    return walletInfo.name === creatOKXWalletInfo().name
}


export function openUniversalWallet(walletInfo:WalletInfo | undefined | null,twaReturnUrl:string|undefined,sessionNameSpace?:ConnectNamespaceMap){
    logDebug("wallets openUniversalWallet")
    if (showUniversalTgWalletBySessionNameSpaces(sessionNameSpace)){
        openWallet(walletInfo,twaReturnUrl)
    }
}

export function openTonWallet(walletInfo:WalletInfo | undefined | null,twaReturnUrl:string|undefined){
    logDebug("wallets openTonWallet")
    if (showTonTgWallet()){
        openWallet(walletInfo,twaReturnUrl)
    }
}

export function openWallet(walletInfo:WalletInfo | undefined | null,twaReturnUrl:string|undefined){
    // 当展示tg wallet 时候，openUniversalLink = false,需要在ui 中执行跳转；
    logDebug("wallets openWallet")
    if (walletInfo){
        if (isAppWallet(walletInfo)){
            logDebug(`wallets openWallet openOKXDeeplinkWithFallback ${walletInfo.universalLink}`)
            openOKXDeeplinkWithFallback(walletInfo.deepLink!)
        }else {
            logDebug(`wallets openWallet openOKXTMAWalletlinkWithFallback ${walletInfo.universalLink}`)
            openOKXTMAWalletlinkWithFallback(getTelegramWalletTWAUrl(undefined,twaReturnUrl,browserDebug()),browserDebug())
        }
    }
}



export function browserDebug(){
    return !(isInTelegramBrowser() || isInTMA())
}

export function openWalletForUIRequest(walletInfo:WalletInfo | undefined | null){
    if (!isAppWallet(walletInfo)){
        return true
    }
    return (isMobile())
}
