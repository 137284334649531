import en from "./assets/i18n/en.json";
import ru from "./assets/i18n/ru.json";
import zh_CN from "./assets/i18n/zh_CN.json";
import ar_AE from "./assets/i18n/ar_AE.json";
import cs_CZ from "./assets/i18n/cs_CZ.json";
import de_DE from "./assets/i18n/de_DE.json";
import es_ES from "./assets/i18n/es_ES.json";
import es_LAT from "./assets/i18n/es_LAT.json";
import fr_FR from "./assets/i18n/fr_FR.json";
import id_ID from "./assets/i18n/id_ID.json";
import it_IT from "./assets/i18n/it_IT.json";
import nl_NL from "./assets/i18n/nl_NL.json";
import pl_PL from "./assets/i18n/pl_PL.json";
import pt_BR from "./assets/i18n/pt_BR.json";
import pt_PT from "./assets/i18n/pt_PT.json";
import ro_RO from "./assets/i18n/ro_RO.json";
import tr_TR from "./assets/i18n/tr_TR.json";
import uk_UA from "./assets/i18n/uk_UA.json";
import vi_VN from "./assets/i18n/vi_VN.json";
import { Locales } from "../models";

export const i18nDictionary: Record<Locales, object> = {
  en_US: parseDictionary(en),
  ru_RU: parseDictionary(ru),
  zh_CN: parseDictionary(zh_CN),
  zh: parseDictionary(zh_CN),
  ar_AE: parseDictionary(ar_AE),
  cs_CZ: parseDictionary(cs_CZ),
  de_DE: parseDictionary(de_DE),
  es_ES: parseDictionary(es_ES),
  es_LAT: parseDictionary(es_LAT),
  fr_FR: parseDictionary(fr_FR),
  id_ID: parseDictionary(id_ID),
  it_IT: parseDictionary(it_IT),
  nl_NL: parseDictionary(nl_NL),
  pl_PL: parseDictionary(pl_PL),
  pt_BR: parseDictionary(pt_BR),
  pt_PT: parseDictionary(pt_PT),
  ro_RO: parseDictionary(ro_RO),
  tr_TR: parseDictionary(tr_TR),
  uk_UA: parseDictionary(uk_UA),
  vi_VN: parseDictionary(vi_VN),
};

// replace '$key1.key2.key3' with it's value
function parseDictionary(
  dictionary: Record<string, unknown>
): Record<string, unknown> {
  const refSymbol = "$";

  const iterate = (subDictionary: Record<string, unknown>): void => {
    Object.entries(subDictionary).forEach(([key, value]) => {
      if (typeof value === "object" && value) {
        return iterate(value as Record<string, unknown>);
      }

      if (typeof value === "string") {
        if (value[0] === refSymbol) {
          const path = value.slice(1).split(".");
          let obj: Record<string, unknown> = dictionary;
          path.forEach((item) => {
            if (item in obj) {
              obj = obj[item] as Record<string, unknown>;
            } else {
              throw new Error(
                `Cannot parse translations: there is no property ${item} in translation`
              );
            }
          });

          subDictionary[key] = obj;
        }

        if (value.slice(0, 2) === `\\${refSymbol}`) {
          subDictionary[key] = value.slice(1);
        }
      }
    });
  };

  iterate(dictionary);
  return dictionary;
}
