import type {Component} from 'solid-js';
import {Show} from 'solid-js';
import {Dynamic, Portal} from 'solid-js/web';
import {ThemeProvider} from 'solid-styled-components';
import {AccountButton} from './views/account-button';
import {createI18nContext, I18nContext} from '@solid-primitives/i18n';
import {TonSingleWalletModal} from "./views/modals/wallets-modal/single-ton-wallet-modal";
import {i18nDictionary} from "../app/i18n";
import {
  defineStylesRoot,
  fixMobileSafariActiveTransition,
} from "../app/utils/web-api";
import {
  GlobalStyles,
  globalStylesTag,
} from "../app/styles/global-styles";
import {themeState} from "../app/state/theme-state";
import {ActionsModal} from "../app/views/modals";
import {OKXTonConnectUI} from "./okx-connect-ton-ui";
import {appState, setAppState} from "../config/app.state";
import {ConnectorContext} from "../config/connector.context";
import {OKXConnectUiContext} from "../config/okx-connect-ui.context";
import { tonAction, setTonAction } from '../app/state/ton-modals-state';

export type TonAppProps = {
    connectUI: OKXTonConnectUI;
};
export const TONApp: Component<TonAppProps> = props => {
    const translations = createI18nContext(i18nDictionary, appState.language);
    defineStylesRoot();
    fixMobileSafariActiveTransition();
    setAppState({contextType:'TONContext'})
    return (
      <I18nContext.Provider value={translations}>
        <OKXConnectUiContext.Provider value={props.connectUI}>
          <ConnectorContext.Provider value={appState.tonConnector!}>
            <GlobalStyles />
            <ThemeProvider theme={themeState}>
              <Show when={appState.buttonRootId}>
                <Portal
                  mount={document.getElementById(appState.buttonRootId!)!}
                >
                  <AccountButton />
                </Portal>
              </Show>
              <Dynamic component={globalStylesTag}>
                <TonSingleWalletModal />
                <ActionsModal action={tonAction()} setAction={setTonAction}/>
              </Dynamic>
            </ThemeProvider>
          </ConnectorContext.Provider>
        </OKXConnectUiContext.Provider>
      </I18nContext.Provider>
    );
};

export default TONApp;