import { Component, createSignal, Show, useContext } from "solid-js";
import { Text } from "../../../../app/components";
import { CopyIcon } from "../../../../app/components/icons/copy-icon";
import { DisconnectIcon } from "../../../../app/components/icons/disconnect-icon";
import { Styleable } from "../../../../app/models/styleable";
import { Translateable } from "../../../../app/models/translateable";
import { toUserFriendlyAddress } from "@okxconnect/tonsdk";
import { copyToClipboard } from "../../../../app/utils/copy-to-clipboard";
import {
  AccountButtonDropdownStyled,
  MenuButtonStyled,
  UlStyled,
} from "./style";
import { OKXConnectUiContext } from "../../../../config/okx-connect-ui.context";
import { OKXTonConnectUI } from "../../../okx-connect-ton-ui";
// import { TONConnectUiContext } from "../../../../config/okx-connect-ui.context";

const MenuItemText: Component<{ children: string } & Translateable> = (
  props
) => (
  <Text translationKey={props.translationKey} fontSize="15px" fontWeight="590">
    {props.children}
  </Text>
);

export interface AccountButtonDropdownProps extends Styleable {
  onClose: () => void;
  ref: HTMLDivElement | undefined;
}

export const AccountButtonDropdown: Component<AccountButtonDropdownProps> = (
  props
) => {
  const tonConnectUi = useContext(OKXConnectUiContext)! as OKXTonConnectUI;
  const [isCopiedShown, setIsCopiedShown] = createSignal(false);

  const onCopy = async (): Promise<void> => {
    const userFriendlyAddress = toUserFriendlyAddress(
      tonConnectUi.account!.address
    );
    await copyToClipboard(userFriendlyAddress);
    setIsCopiedShown(true);

    setTimeout(() => setIsCopiedShown(false), 1000);
  };

  const onDisconnect = (): void => {
    tonConnectUi.disconnect();
    props.onClose();
  };

  return (
    <AccountButtonDropdownStyled
      ref={props.ref}
      class={props.class}
      data-tc-dropdown="true"
    >
      <UlStyled>
        <li>
          <MenuButtonStyled onClick={() => onCopy()}>
            <CopyIcon />
            <Show when={!isCopiedShown()}>
              <MenuItemText translationKey="button.dropdown.copy">
                Copy address
              </MenuItemText>
            </Show>
            <Show when={isCopiedShown()}>
              <MenuItemText translationKey="button.dropdown.copied">
                Address copied!
              </MenuItemText>
            </Show>
          </MenuButtonStyled>
        </li>
        <li>
          <MenuButtonStyled onClick={() => onDisconnect()}>
            <DisconnectIcon />
            <MenuItemText translationKey="button.dropdown.disconnect">
              Disconnect
            </MenuItemText>
          </MenuButtonStyled>
        </li>
      </UlStyled>
    </AccountButtonDropdownStyled>
  );
};
