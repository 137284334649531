import { render } from 'solid-js/web';

import {
    lastTonSelectedWalletInfo,
    setTonAction,
    setLastTonSelectedWalletInfo,
    setTonSingleWalletModalState,
} from '../app/state/ton-modals-state';
import {WalletInfoWithOpenMethod, WalletOpenMethod, WalletsModalCloseReason} from '../models';
import {logDebug, WalletInfo} from "@okxconnect/core";
import {OKXTonConnectUI} from "../ton";
import {OKXUniversalConnectUI} from "../universal/okx-connect-universal-ui";
import {TONApp} from "../ton/TONApp";
import {UniversalApp} from "../universal/UniversalApp";
import { Action } from '../app/models/action-modal';

export const tonWidgetController = {
    openSingleWalletModal: (walletInfo: WalletInfo): void => {
        logDebug(`tonWidgetController : openSingleWalletModal : ${JSON.stringify(walletInfo)}`)
        void setTimeout(() =>
            setTonSingleWalletModalState({
                status: 'opened',
                closeReason: null,
                walletInfo: walletInfo
            })
        );
    },
    closeSingleWalletModal: (reason: WalletsModalCloseReason): void =>
        void setTimeout(() =>
            setTonSingleWalletModalState({
                status: 'closed',
                closeReason: reason
            })
        ),
    setAction: (action: Action): void => void setTimeout(() => setTonAction(action)),
    clearAction: (): void => void setTimeout(() => {console.log('clearAction'); setTonAction(null)}),
    getSelectedWalletInfo: ():
        | WalletInfoWithOpenMethod
        | {
              openMethod: WalletOpenMethod;
          }
        | null => lastTonSelectedWalletInfo(),
    removeSelectedWalletInfo: (): void => setLastTonSelectedWalletInfo(null),
    renderApp: (root: string, connectUI:OKXTonConnectUI ): (() => void) => {
       return render(
            () => <TONApp connectUI={connectUI} />,
            document.getElementById(root) as HTMLElement
        )
    },
    renderUniversalApp: (root: string, connectUI: OKXUniversalConnectUI): (() => void) => {
       return render(
            () => <UniversalApp connectUI={connectUI} />,
            document.getElementById(root) as HTMLElement
        )
    }
};
